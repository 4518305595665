






import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { SummaryViewModel } from '../../viewmodels/summary-viewmodel'

@Observer
@Component({
  components: {
    'top-post-grid': () => import('./components/top-posts/top-post-grid.vue'),
  },
})
export default class TopPost extends Vue {
  @Inject() vm!: SummaryViewModel
}
